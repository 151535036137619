import {useAuth0} from '@auth0/auth0-react';
import {Navigate, useSearchParams} from 'react-router-dom';
import RouteEnum from '../../enums/RouteEnum';
import {Grid, Typography} from "@mui/material";

const Auth0CallbackPage = () => {
  const {error} = useAuth0();
  const [searchParams] = useSearchParams();

  if (error) {
    return (
      <Grid container flexDirection="column">
        <Grid>
          <Typography>
            {"Error processing auth callback"}
          </Typography>
        </Grid>
        <Grid>
          <Typography>{error.message}</Typography>
        </Grid>
      </Grid>
    );
  }

  if (!searchParams.size) {
    return <Navigate to={RouteEnum.Welcome}/>;
  }

  return null;
};

export default Auth0CallbackPage;
