import React, {SyntheticEvent, useState} from "react";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import {Box} from "@mui/material";
import DeviceListEditor from "./components/DeviceListEditor";
import MobileBuildsTable from "./components/MobileBuildsTable";
import MdmPortal from "./components/MdmPortal";

const MobileBuilds = () => {
  const [value, setValue] = useState('1');

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext  value={value}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <TabList variant="scrollable" scrollButtons="auto" onChange={handleTabChange}>
          <Tab label="Builds" value="1"/>
          <Tab label="Allowed Devices" value="2"/>
          <Tab label="Get UDID" value="3"/>
        </TabList>
      </Box>
      <TabPanel value="1">
        <MobileBuildsTable/>
      </TabPanel>
      <TabPanel value="2">
        <DeviceListEditor/>
      </TabPanel>
      <TabPanel value="3">
        <MdmPortal/>
      </TabPanel>
    </TabContext>
  );
};

export default MobileBuilds;
