import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {IconButton} from "@mui/material";
import {FileDownload} from "@mui/icons-material";
import {useApiClients} from "../../contexts/ApiClients";
import {useCallback, useEffect, useState} from "react";
import {BuildObject, ListBuildsRequest} from "@uniqkey-devops-platform-builds-service/api-client/dist/builds_pb";
import {toDateTimeString} from "../../utils/toDateTimeString";
import {useSearchParams} from "react-router-dom";

const customToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton/>
    <GridToolbarFilterButton/>
    <GridToolbarDensitySelector/>
    <GridToolbarQuickFilter/>
  </GridToolbarContainer>
);

interface ExtensionBuildObjectsRow {
  id: string;
  uploaded: number;
  release: string;
  browser: string;
  environment: string;
  build: string;
  branch: string;
}

const ExtensionBuilds = () => {
  const [tableRows, setTableRows] = useState<ExtensionBuildObjectsRow[]>([]);
  const {buildsClient} = useApiClients();

  const [searchParams] = useSearchParams();

  const fetchExtensionBuilds = useCallback(async () => {
    const request = new ListBuildsRequest();
    request.setSvcname("browser-extensions");

    const buildObjectsResponse = await buildsClient.listBuilds(request);
    const rows: ExtensionBuildObjectsRow[] = buildObjectsResponse.getBuildsList()
      .map((item: BuildObject) => {
        const tagsMap = item.getTagsMap();
        const tagsObject = Array.from(tagsMap.entries())
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {} as Record<string, string>);

        return {
          id: item.getUrl(),
          uploaded: item.getLastmodified(),
          release: tagsObject.release,
          browser: tagsObject.browser,
          environment: tagsObject.environment,
          build: tagsObject.build,
          branch: tagsObject.branch,
        };
      });

    setTableRows(rows);

  }, [buildsClient]);

  useEffect(() => {
    fetchExtensionBuilds();
  }, [fetchExtensionBuilds]);

  const columns: GridColDef[] = [
    {field: 'release', headerName: 'Release', width: 125},
    {field: 'browser', headerName: 'Browser', width: 125},
    {field: 'environment', headerName: 'Env', width: 125},
    {field: 'build', headerName: 'Build', width: 125},
    {field: 'branch', headerName: 'Branch', width: 250},
    {
      field: 'uploaded',
      headerName: 'Uploaded',
      width: 150,
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return toDateTimeString(value as number);
      },
    },
    {
      field: 'download',
      headerName: 'Download',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton href={params.id.toString()} target="_blank" rel="noopener" color='primary'>
          <FileDownload/>
        </IconButton>
      ),
    },
  ];

  return (
    <DataGrid
      sx={{
        height: '85vh', width: '75vw', margin: 'auto', padding: 1.5
      }}
      initialState={{
        sorting: {sortModel: [{field: 'uploaded', sort: 'desc'}]},
        pagination: {paginationModel: {pageSize: 25}},
        filter: {
          filterModel: {
            items: [],
            quickFilterValues: [searchParams.get('q')],
          },
        },
      }}
      pageSizeOptions={[10, 25, 50]}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableRowSelectionOnClick
      rows={tableRows}
      columns={columns}
      slots={{toolbar: customToolbar}}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
    />
  )
};

export default ExtensionBuilds;
