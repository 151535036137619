import {Chip, List, ListItem} from "@mui/material";

export interface IBuildsDetailsListProps {
  bucket: string,
  name: string,
  ext: string,
  envs?: Array<string>
}

const BuildsDetailsList = ({
                             bucket,
                             name,
                             ext,
                             envs = ["dev", "qa1", "qa2", "qa3", "staging", "demo", "production"]
                           }: IBuildsDetailsListProps) => {

  return (
    <List>
      {envs?.map((env => {
        ext = (name === 'uniqkey-import-agent' &&  env === 'production') ? 'msi' : ext;
        let url = `https://${bucket}-${env}.s3.eu-central-1.amazonaws.com/${name}-${env}.${ext}`;
        
        return (
          <ListItem key={url}>
            <Chip
              clickable
              component="a"
              color='primary'
              variant="outlined"
              label={`${name}-${env}.${ext}`}
              href={url}
            />
          </ListItem>
        )
      }))}
    </List>
  )
};

export default BuildsDetailsList;
