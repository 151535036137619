import dayjs from "dayjs";
import {EnvBooking} from "@uniqkey-devops-platform-envs-service/api-client/dist/envs_pb";

export const getActiveBooking = (bookingsList: EnvBooking[]) => {
  const now = dayjs();

  for (let i = 0; i < bookingsList.length; i++) {
    const booking = bookingsList[i];

    const fromDate = dayjs(booking.getFrom());
    const untilDate = dayjs(booking.getUntil());

    const isWithin = (now.isAfter(fromDate) || now.isSame(fromDate, 'minute')) &&
      (now.isBefore(untilDate) || now.isSame(untilDate, 'minute'));

    if (isWithin) {
      return booking;
    }
  }

  return null;
};
