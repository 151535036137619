import {ReactNode} from 'react';
import AppBar from "../../components/AppBar";
import {useLocation} from "react-router-dom";

interface IAuthenticatedLayoutProps {
  children: ReactNode;
}

const AuthenticatedLayout = (props: IAuthenticatedLayoutProps) => {
  const {children} = props;
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/' ? (
        <AppBar>{children}</AppBar>
      ) : (
        children
      )}
    </>
  );
};

export default AuthenticatedLayout;
