import {useCallback, useMemo} from 'react';
import {Auth0Provider, Auth0ProviderOptions} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import config from '../../config';

type TRedirectCallback = NonNullable<Auth0ProviderOptions['onRedirectCallback']>;

interface IAuth0ProviderWithNavigateProps {
  children: Auth0ProviderOptions['children'];
}

export const Auth0ProviderWithNavigate = (props: IAuth0ProviderWithNavigateProps) => {
  const {children} = props;

  const navigate = useNavigate();

  const authorizationParams = useMemo(() => ({
    audience: config.auth0Audience,
    redirect_uri: config.auth0CallbackURL,
  }), []);

  const handleRedirectCallback = useCallback<TRedirectCallback>((appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  }, [navigate]);

  if (!(
    config.auth0Domain
    && config.auth0ClientId
    && config.auth0CallbackURL
    && config.auth0Audience
  )) {
    console.error('Auth0ProviderWithNavigate error');
    return null;
  }

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      authorizationParams={authorizationParams}
      onRedirectCallback={handleRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
