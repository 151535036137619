import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import RouteEnum from "../../enums/RouteEnum";

const WelcomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`${RouteEnum.Environments}/staging`);
  }, [navigate]);

  return null;
};

export default WelcomePage;
