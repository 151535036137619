import {ReactNode} from 'react';

interface IUnauthenticatedLayoutProps {
  children: ReactNode;
}

const UnauthenticatedLayout = (props: IUnauthenticatedLayoutProps) => {
  const {children} = props;

  return (
    <div>
      {children}
    </div>
  );
};

export default UnauthenticatedLayout;
