import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {toDateTimeString} from "../../../../utils/toDateTimeString";
import {useCallback, useEffect, useState} from "react";
import AddBookingModal from "../AddBookingModal";
import {
  CancelEnvBookingRequest,
  EnvBooking,
  GetEnvDetailsRequest
} from "@uniqkey-devops-platform-envs-service/api-client/dist/envs_pb";
import {useApiClients} from "../../../../contexts/ApiClients";

export interface IManageBookingsModalProps {
  isOpen: boolean,
  onClose: () => void,
  onChange: () => void,
  env: string,
}


const ManageBookingsModal = (props: IManageBookingsModalProps) => {
  const {
    isOpen,
    onClose,
    onChange,
    env,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {envsClient} = useApiClients();

  const [bookings, setBookings] = useState<EnvBooking[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const fetchEnvDetails = useCallback(async () => {
    const request = new GetEnvDetailsRequest();
    request.setEnvname(env);

    const envDetailsResponse = await envsClient.getEnvDetails(request);
    setBookings(envDetailsResponse.getBookingsList());
  }, [env, envsClient]);

  useEffect(() => {
    fetchEnvDetails();
  }, [fetchEnvDetails]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedItems = bookings.map((item) => item.getRelease());
      setSelectedItems(newSelectedItems);
      return;
    }
    setSelectedItems([]);
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1));
    }

    setSelectedItems(newSelected);
  };

  const isSelected = (id: string) => selectedItems.indexOf(id) !== -1;

  const deleteSelectedItems = async () => {
    const cancellationPromises = selectedItems.map((release) => {
      const request = new CancelEnvBookingRequest();
      request.setEnvname(env);
      request.setRelease(release);
      return envsClient.cancelEnvBooking(request);
    });
    await Promise.all(cancellationPromises);

    const newTableItems = bookings
      .filter(item => !selectedItems.includes(item.getRelease()));
    setBookings(newTableItems);
    setSelectedItems([]);
    onChange();
  };

  const handleOnAdd = async () => {
    await fetchEnvDetails();
    onChange();
  }

  const handleClose = () => {
    setSelectedItems([]);
    setBookings(bookings);
    onClose();
  };

  const [isAddBookingOpen, setIsAddBookingOpen] = useState(false);

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#36454f',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={fullScreen}
            sx={{
              '& .MuiDialog-paper': { minWidth: 350 },
            }}>
      <DialogTitle>
        <Typography variant="body1" sx={{textTransform: 'capitalize'}}>
          {env} {bookings.length > 0 ? 'bookings management' : 'has no bookings'}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {bookings.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="basic table" size={fullScreen ? 'small' : 'medium'}>
              <TableHead>
                <TableRow>
                  <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                    <Checkbox
                      indeterminate={selectedItems.length > 0 && selectedItems.length < bookings.length}
                      checked={bookings.length > 0 && selectedItems.length === bookings.length}
                      onChange={handleSelectAll}
                    />
                  </StyledTableCell>
                  <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                    Booker
                  </StyledTableCell>
                  <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                    Release
                  </StyledTableCell>
                  <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                    From
                  </StyledTableCell>
                  <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                    Until
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.map((item, index) => (
                  <StyledTableRow key={item.getRelease()}>
                    <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                      <Checkbox
                        checked={isSelected(item.getRelease())}
                        onChange={(e) => handleSelect(e, item.getRelease())}
                      />
                    </StyledTableCell>
                    <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                      {item.getBooker()}
                    </StyledTableCell>
                    <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                      {item.getRelease()}
                    </StyledTableCell>
                    <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                      {toDateTimeString(item.getFrom())}
                    </StyledTableCell>
                    <StyledTableCell padding={fullScreen ? 'none' : 'normal'}>
                      {toDateTimeString(item.getUntil())}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color='info'>
          Close
        </Button>
        <div style={{flexGrow: 1}}/>
        {bookings.length > 0 && (
          <Button color='error'
                  onClick={deleteSelectedItems}
                  disabled={selectedItems.length === 0}
          >
            Delete
          </Button>
        )}
        <Button
          variant='contained'
          disabled={selectedItems.length > 0}
          onClick={() => setIsAddBookingOpen(true)}>
          Add
        </Button>
      </DialogActions>

      <AddBookingModal env={env}
                       onAdd={handleOnAdd}
                       isOpen={isAddBookingOpen}
                       onClose={() => setIsAddBookingOpen(false)}/>
    </Dialog>
  );
};

export default ManageBookingsModal;
