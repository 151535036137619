import {Alert, Box, Button, TextField} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useCallback, useEffect, useState} from "react";
import {useApiClients} from "../../../../contexts/ApiClients";
import {
  GetObjectContentRequest,
  PutObjectContentRequest
} from "@uniqkey-devops-platform-builds-service/api-client/dist/builds_pb";

interface IFormInput {
  content: string,
}

const DeviceListEditor = () => {
  const {buildsClient} = useApiClients();
  const [deviceListContent, setDeviceListContent] = useState<string | null>(null);

  const {
    handleSubmit, reset, control,
    formState: {isDirty, errors}
  } = useForm<IFormInput>({
    defaultValues: {
      content: 'Device ID____Device Name\n'
    }
  });

  const fetchDeviceListContent = useCallback(async () => {
    const request = new GetObjectContentRequest();
    request.setSvcname('mobile-apps');
    request.setKey('devices.txt');

    const response = await buildsClient.getObjectContent(request);
    let content = response.getContent();
    content = content.replace(/\t/g, '____');
    setDeviceListContent(content);
  }, [buildsClient]);

  useEffect(() => {
    fetchDeviceListContent();
  }, [fetchDeviceListContent]);

  useEffect(() => {
    if (deviceListContent !== null) {
      reset({content: deviceListContent});
    }
  }, [deviceListContent, reset]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    let content = data.content;
    if (!content.endsWith('\n')) {

      content += '\n';
    }
    content = content.replace(/____/g, '\t');

    const request = new PutObjectContentRequest();
    request.setSvcname('mobile-apps');
    request.setKey('devices.txt');
    request.setContent(content);

    await buildsClient.putObjectContent(request);
    reset({content: data.content});
  }

  const validateContent = (input: string) => {
    const lines = input.split('\n');
    const regex = /^.*[^_]+____.*[^_]+.*$/; // check if 4 underscores separate items
    
    return lines.every(line => regex.test(line) || line === "") || "Invalid format";
  };

  return (
    <Box>
      <Alert severity="warning">
        Important: Device ID column must be divided from Device Name column with exactly 4 underscore characters '____'
      </Alert>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Controller
          name="content"
          control={control}
          rules={{validate: validateContent}}
          render={({field}) => (
            <TextField
              {...field}
              label="Approved Devices"
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              required
              error={!!errors.content}
              helperText={errors.content ? errors.content.message : ''}
            />
          )}
        />

        <Button variant='contained' type='submit' disabled={!isDirty}>
          Submit
        </Button>
      </form>
    </Box>
  );
};
export default DeviceListEditor;
