import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';

const AuthenticatedComponent = () => (
  <AuthenticatedLayout>
    <Outlet />
  </AuthenticatedLayout>
);

const OnRedirecting = () => (
  <Suspense fallback={null} />
);

const AuthenticatedRoute = withAuthenticationRequired(AuthenticatedComponent, {
  onRedirecting: OnRedirecting,
});

export default AuthenticatedRoute;
