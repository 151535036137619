const config = {
  build: process.env.REACT_APP_BUILD,
  requestTimeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT as string, 10) ?? 10000,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0CallbackURL: process.env.REACT_APP_AUTH0_CALLBACK_URL,
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export default config;
