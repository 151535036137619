import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import dayjs, {Dayjs} from "dayjs";
import {useApiClients} from "../../../../contexts/ApiClients";
import {BookEnvRequest, EnvBooking} from "@uniqkey-devops-platform-envs-service/api-client/dist/envs_pb";

export interface IAddBookingModalProps {
  env: string,
  isOpen: boolean,
  onClose: () => void,
  onAdd: () => void,
}

interface IFormInput {
  booker: string,
  release: string,
  from: Dayjs,
  until: Dayjs,
}

const AddBookingModal = (props: IAddBookingModalProps) => {
  const {
    env,
    isOpen,
    onClose,
    onAdd,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {envsClient} = useApiClients();

  const {register, handleSubmit, control} = useForm({
    defaultValues: {
      booker: '',
      release: '',
      from: dayjs().add(1, 'hour'),
      until: dayjs().add(1, 'day'),
    },
  })

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const booking = new EnvBooking();
    booking.setBooker(data.booker);
    booking.setRelease(data.release);
    booking.setFrom(data.from.valueOf());
    booking.setUntil(data.until.valueOf());

    const request = new BookEnvRequest();
    request.setEnvname(env);
    request.setBooking(booking);

    await envsClient.bookEnv(request);
    onClose();
    onAdd();
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={fullScreen}
            sx={{
              '& .MuiDialog-paper': {minWidth: 350},
            }}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          <Typography variant="body1" sx={{textTransform: 'capitalize'}}>
            Add new booking for {env}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid container direction='column' justifyContent='space-between' gap={2.5} sx={{paddingTop: 2}}>
            <TextField {...register("booker")}
                       placeholder="Mykola"
                       label="Your name"
                       variant="outlined"
                       required/>
            <TextField {...register("release")}
                       placeholder="3.0.1"
                       label="Release"
                       variant="outlined"
                       required/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                control={control}
                name="from"
                rules={{required: true}}
                render={({field: {onChange, value, ref}}) => (
                  <DateTimePicker
                    disablePast
                    label="Book from"
                    onChange={onChange}
                    onAccept={onChange}
                    value={value}
                    inputRef={ref}
                  />
                )}
              />
              <Controller
                control={control}
                name="until"
                rules={{required: true}}
                render={({field: {onChange, value, ref}}) => (
                  <DateTimePicker
                    disablePast
                    label="Book until"
                    onChange={onChange}
                    onAccept={onChange}
                    value={value}
                    inputRef={ref}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='info' onClick={onClose}>
            Cancel
          </Button>
          <div style={{flexGrow: 1}}/>
          <Button variant='contained' type='submit'>
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddBookingModal;
