import {OverridableStringUnion} from "@mui/types";
import {ChipPropsColorOverrides} from "@mui/material/Chip/Chip";

export interface EnvStateDetails {
  code: number,
  name: string,
  color: OverridableStringUnion<
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    ChipPropsColorOverrides
  >
}

export const envStateMap: EnvStateDetails[] = [
  {code: 0, name: 'unknown', color: 'warning'},
  {code: 1, name: 'creating', color: 'info'},
  {code: 2, name: 'created', color: 'info'},
  {code: 3, name: 'booting', color: 'info'},
  {code: 4, name: 'running', color: 'success'},
  {code: 5, name: 'stopping', color: 'warning'},
  {code: 6, name: 'stopped', color: 'info'},
  {code: 7, name: 'deleting', color: 'error'},
];
