import React, {createContext, ReactNode, useContext, useMemo} from "react";

import {BuildsClient} from "@uniqkey-devops-platform-builds-service/api-client";
import {EnvironmentsClient} from "@uniqkey-devops-platform-envs-service/api-client";

interface IApiClientsContext {
  buildsClient: BuildsClient;
  envsClient: EnvironmentsClient;
}

const ApiClientsContext = createContext<IApiClientsContext | null>(null);

export const ApiClientsProvider: React.FC<{ children: ReactNode }> = ({children}) => {
  const buildsApiServerUrl = process.env.REACT_APP_BUILDS_API_SERVER_URL;
  if (!buildsApiServerUrl) {
    throw new Error("buildsApiServerUrl is not set, check REACT_APP_BUILDS_API_SERVER_URL");
  }

  const envsApiServerUrl = process.env.REACT_APP_ENVS_API_SERVER_URL;
  if (!envsApiServerUrl) {
    throw new Error("envsApiServerUrl is not set, check REACT_APP_ENVS_API_SERVER_URL");
  }

  const buildsClient = useMemo(() => {
    return new BuildsClient(buildsApiServerUrl);
  }, [buildsApiServerUrl]);

  const envsClient = useMemo(() => {
    return new EnvironmentsClient(envsApiServerUrl);
  }, [envsApiServerUrl]);

  return (
    <ApiClientsContext.Provider value={{buildsClient, envsClient}}>
      {children}
    </ApiClientsContext.Provider>
  );
};


export const useApiClients = () => {
  const context = useContext(ApiClientsContext);
  if (context === null) {
    throw new Error('useApiClients must be used within a ApiClientsProvider');
  }

  return context;
};
