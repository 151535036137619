import React from "react";
import Router from "./Router";
import {BrowserRouter} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import {ColorModeProvider} from "./contexts/ColorMode";
import {ApiClientsProvider} from "./contexts/ApiClients";
import Auth0ProviderWithNavigate from "./components/Auth0ProviderWithNavigate";

import './App.css';


function App() {
  return (
    <CssBaseline>
      <ApiClientsProvider>
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <ColorModeProvider>
              <Router/>
            </ColorModeProvider>
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </ApiClientsProvider>
    </CssBaseline>
  );
}

export default App;
