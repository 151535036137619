import {Box, Card, CardContent, CardHeader} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import BuildsDetailsList from "../Builds/components/BuildsDetailsList";

const DesktopBuilds = () => {

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader title='Uniqkey desktop app & agents'/>
        <CardContent>
          <Accordion>
            <AccordionSummary>Desktop app</AccordionSummary>
            <AccordionDetails>
              <BuildsDetailsList
                bucket='uniqkey-desktop'
                name='uniqkey-desktop'
                ext='msi'
                envs={['dev', 'qa1', 'qa2', 'demo', 'staging', 'production']}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>Import agent</AccordionSummary>
            <AccordionDetails>
              <BuildsDetailsList
                bucket='uniqkey-desktop'
                name='uniqkey-import-agent'
                ext='zip'
                envs={['dev', 'qa1', 'qa2', 'demo', 'staging', 'production']}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>Terminal agent</AccordionSummary>
            <AccordionDetails>
              <BuildsDetailsList
                bucket='uniqkey-desktop'
                name='uniqkey-terminal-agent'
                ext='msi'
                envs={['dev', 'demo', 'staging', 'production']}
              />
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Box>
  )
};

export default DesktopBuilds;
