import {Box, Button, Card, CardActions, CardContent, Chip, Divider, Grid, Paper, Typography} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {toDateTimeString} from "../../../../utils/toDateTimeString";
import {getActiveBooking} from "../../../../utils/getActiveBooking";
import {EnvStateDetails, envStateMap} from "../../../../utils/envStateMap";
import ManageBookingsModal from "../ManageBookingsModal";
import {EnvBooking, GetEnvDetailsRequest} from "@uniqkey-devops-platform-envs-service/api-client/dist/envs_pb";
import {useParams} from "react-router-dom";

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import dayjs, {Dayjs} from "dayjs";
import {useApiClients} from "../../../../contexts/ApiClients";

const CARD_SX = {
  maxWidth: 400,
  margin: 'auto'
};

export type EnvCardRouteParams = {
  env: string
}

const EnvCard = () => {
  const {env} = useParams<EnvCardRouteParams>();
  const {envsClient} = useApiClients();

  const [bookings, setBookings] = useState<EnvBooking[]>([]);
  const [envStateDetails, setEnvStateDetails] = useState<EnvStateDetails>();
  const [activeBooking, setActiveBooking] = useState<EnvBooking | null>(null);

  const fetchEnvDetails = useCallback(async () => {
    const request = new GetEnvDetailsRequest();
    request.setEnvname(env!);

    const envDetailsResponse = await envsClient.getEnvDetails(request);
    const bookingsList = envDetailsResponse.getBookingsList();

    setBookings(bookingsList);
    setActiveBooking(getActiveBooking(bookingsList));
    setEnvStateDetails(envStateMap.find(e => e.code === envDetailsResponse.getState()));
  }, [env, envsClient]);

  useEffect(() => {
    fetchEnvDetails();
  }, [fetchEnvDetails]);

  const [isManageBookingsOpen, setIsManageBookingsOpen] = useState<boolean>(false);

  const isDateWithinBookings = (date: Dayjs): boolean => {
    return bookings.some(booking => {
      const fromDate = dayjs(booking.getFrom());
      const untilDate = dayjs(booking.getUntil());

      // Check if the date is the same or after the start date and the same or before the end date
      return date.isSame(fromDate, 'day') || date.isSame(untilDate, 'day') ||
        (date.isAfter(fromDate, 'day') && date.isBefore(untilDate, 'day'));
    });
  };

  return (
    <Card elevation={3} sx={CARD_SX}>
      <CardContent>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant="body1" sx={{textTransform: 'capitalize'}}>
              {env}
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              color={envStateDetails?.color}
              label={<Typography variant="body2" sx={{color: envStateDetails?.color}}>
                {envStateDetails?.name}
              </Typography>}
            />
          </Grid>
        </Grid>

        <Box minHeight={100} paddingTop={2.5} paddingBottom={2.5}>
          {activeBooking ? (
            <div style={{paddingTop: '1vh'}}>
              <Divider variant="middle">
                <Typography variant="body2">
                  {`Booked until ${toDateTimeString(activeBooking.getUntil())} by ${activeBooking.getBooker()}`}
                </Typography>
              </Divider>
              <Typography fontSize="lg" fontWeight="sm" sx={{textAlign: 'center'}}>
                {`Release ${activeBooking.getRelease()}`}
              </Typography>
            </div>
          ) : (
            <div style={{paddingTop: '1vh'}}>
              <Divider variant="middle">
                <Typography variant="body2">
                  No active bookings
                </Typography>
              </Divider>
            </div>
          )}
        </Box>

        <Paper variant='outlined'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              readOnly
              views={['day']}
              reduceAnimations
              shouldDisableDate={(date: Dayjs) => isDateWithinBookings(date)}
              sx={{'.Mui-disabled': {background: 'rgba(255,152,0, 0.75)'}}}
            />
          </LocalizationProvider>
        </Paper>
      </CardContent>

      <CardActions sx={{paddingBottom: 2.5}}>
          <Button size="medium"
                variant='contained'
                sx={{margin: 'auto'}}
                onClick={() => setIsManageBookingsOpen(true)}
        >
          Manage bookings
        </Button>
      </CardActions>

      <ManageBookingsModal
        isOpen={isManageBookingsOpen}
        onClose={() => setIsManageBookingsOpen(false)}
        env={env!}
        onChange={fetchEnvDetails}
      />
    </Card>
  );
};

export default EnvCard;
