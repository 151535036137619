import {Outlet} from 'react-router-dom';
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';

const UnauthenticatedRoute = () => (
  <UnauthenticatedLayout>
    <Outlet/>
  </UnauthenticatedLayout>
);

export default UnauthenticatedRoute;
