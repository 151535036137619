import {
  AppBar as MUIAppBar,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import LocalStorageKeyEnum from "../../enums/LocalStorageKeyEnum";
import RouteEnum from "../../enums/RouteEnum";
import {useColorMode} from "../../contexts/ColorMode";
import {useApiClients} from "../../contexts/ApiClients";
import {ListEnvsRequest} from "@uniqkey-devops-platform-envs-service/api-client/dist/envs_pb";

import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import BookmarkAddedTwoToneIcon from '@mui/icons-material/BookmarkAddedTwoTone';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import ArrowRightRounded from '@mui/icons-material/ArrowRightRounded';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const drawerWidth = 240;

interface AppBarProps {
  children: React.ReactNode
}

const AppBar = (props: AppBarProps) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const navigate = useNavigate();
  const {user, logout} = useAuth0();
  const location = useLocation();
  const theme = useTheme();
  const {toggleColorMode} = useColorMode();
  
  const [envs, setEnvs] = useState<string[]>([]);
  const {envsClient} = useApiClients();
  
  const fetchEnvs = useCallback(async () => {
    const request = new ListEnvsRequest();
    const response = await envsClient.listEnvs(request);
    setEnvs(response.getEnvsList());
  }, [envsClient]);

  useEffect(() => {
    fetchEnvs();
  }, [fetchEnvs]);

  const navTo = (location: string) => {
    setMobileOpen(false);
    navigate(location);
  };

  const handleLogout = useCallback(async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    localStorage.removeItem(LocalStorageKeyEnum.AccessToken);
  }, [logout]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <Toolbar/>
      <Divider/>
      <List>
        <ListItem key='build_artifacts'>
          <ListItemIcon>
            <HandymanTwoToneIcon/>
          </ListItemIcon>
          <ListItemText primary="Build artifacts"/>
        </ListItem>

        <List component="div" disablePadding>
          <ListItemButton
            key='build_artifacts_extension'
            selected={location.pathname === `${RouteEnum.Builds}/extension`}
            onClick={() => navTo(`${RouteEnum.Builds}/extension`)}
            sx={{pl: 4}}>
            <ListItemIcon>
              <ArrowRightRounded/>
            </ListItemIcon>
            <ListItemText primary="Extension"/>
          </ListItemButton>

          <ListItemButton
            key='build_artifacts_desktop'
            selected={location.pathname === `${RouteEnum.Builds}/desktop`}
            onClick={() => navTo(`${RouteEnum.Builds}/desktop`)}
            sx={{pl: 4}}>
            <ListItemIcon>
              <ArrowRightRounded/>
            </ListItemIcon>
            <ListItemText primary="Desktop"/>
          </ListItemButton>

          <ListItemButton
            key='build_artifacts_mobile'
            selected={location.pathname === `${RouteEnum.Builds}/mobile`}
            onClick={() => navTo(`${RouteEnum.Builds}/mobile`)}
            sx={{pl: 4}}>
            <ListItemIcon>
              <ArrowRightRounded/>
            </ListItemIcon>
            <ListItemText primary="Mobile"/>
          </ListItemButton>
        </List>

        <Divider/>

        <ListItem key='env_bookings'>
          <ListItemIcon>
            <BookmarkAddedTwoToneIcon/>
          </ListItemIcon>
          <ListItemText primary="Env bookings"/>
        </ListItem>

        <List component="div" disablePadding>
          {envs.map((env) => (
            <ListItemButton
              key={`env_bookings_${env}`}
              selected={location.pathname === `${RouteEnum.Environments}/${env}`}
              onClick={() => navTo(`${RouteEnum.Environments}/${env}`)}
              sx={{pl: 4}}>
              <ListItemIcon>
                <ArrowRightRounded/>
              </ListItemIcon>
              <ListItemText primary={env}/>
            </ListItemButton>
          ))}
        </List>

        <Divider/>

        <Tooltip followCursor title='In Development'>
            <span>
        <ListItemButton
          key='svc_deployments' disabled>
          <ListItemIcon>
            <RocketLaunchTwoToneIcon/>
          </ListItemIcon>
          <ListItemText primary="Svc deployments"/>
        </ListItemButton>
            </span>
        </Tooltip>
      </List>
    </div>
  );


  return (
    <Box sx={{display: 'flex'}}>
      <MUIAppBar
        position="fixed"
        sx={{
          width: {sm: `calc(100% - ${drawerWidth}px)`},
          ml: {sm: `${drawerWidth}px`},
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr: 2, display: {sm: 'none'}}}
          >
            <MenuIcon/>
          </IconButton>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small"/>}
            sx={{
              flexGrow: 1,
              color: 'white',
              '& .MuiBreadcrumbs-separator': {
                color: 'white',
              },
            }}>
            {location.pathname.split('/')
              .map((segment) => segment.length > 0 ? (
                <Typography
                  key={segment}
                  variant="subtitle1"
                  noWrap
                  component="div"
                  textTransform='capitalize'
                  color='white'>
                  {segment}
                </Typography>
              ) : null)}
          </Breadcrumbs>
          <Tooltip title={`Toggle ${theme.palette.mode === 'dark' ? 'light' : 'dark'} mode`}>
            <IconButton sx={{ml: 1}} onClick={toggleColorMode} color="inherit">
              {theme.palette.mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
            </IconButton>
          </Tooltip>
          <Tooltip title={user?.email}>
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          </Tooltip>
        </Toolbar>
      </MUIAppBar>
      <Box
        component="nav"
        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: {xs: 'block', sm: 'none'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: {xs: 'none', sm: 'block'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component={Paper}
        sx={{
          flexGrow: 1, p: 3, borderRadius: 0,
          height: '100vh', width: {sm: `calc(100% - ${drawerWidth}px)`}
        }}
      >
        <Toolbar/>
        {props.children}
      </Box>
    </Box>
  );

}
export default AppBar;
