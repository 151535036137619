// import {Grid} from "@mui/material";
// import EnvCard from "./components/EnvCard";
// import {useCallback, useEffect, useState} from "react";
// import {useEnvClient} from "../../contexts/EnvClient";
// import {ListEnvsRequest} from "@uniqkey-devops-platform/api-client/dist/env_pb";
//
// const GRID_SX = {};

const EnvironmentsPage = () => {
  // const envClient = useEnvClient();
  //
  // const [envs, setEnvs] = useState<string[]>();
  // const fetchEnvDetails = useCallback(async () => {
  //   const envsResponse = await envClient.listEnvs(new ListEnvsRequest());
  //   setEnvs(envsResponse.getEnvsList());
  // }, [envClient]);
  //
  // useEffect(() => {
  //   fetchEnvDetails();
  // }, [fetchEnvDetails]);

  return (
    <div>Environments Overview (In Development)</div>
    // <Grid container flexDirection='column' gap={2} flex={1}
    //       justifyContent='space-around' alignItems='center'
    //       sx={GRID_SX}>
    //   {envs?.map((env) => (
    //     <Grid xs={12} key={env}>
    //       <EnvCard env={env}/>
    //     </Grid>
    //   ))}
    // </Grid>
  );
};

export default EnvironmentsPage;
