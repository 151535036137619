import {Box, IconButton, Modal} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {useApiClients} from "../../../../contexts/ApiClients";
import {useSearchParams} from "react-router-dom";
import {BuildObject, ListBuildsRequest} from "@uniqkey-devops-platform-builds-service/api-client/dist/builds_pb";
import {toDateTimeString} from "../../../../utils/toDateTimeString";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {FileDownload} from "@mui/icons-material";
import {QRCodeSVG} from "qrcode.react";

interface MobileBuildObjectsRow {
  id: string;
  uploaded: number;
  platform: string;
  environment: string;
  build: string;
  branch: string;
}

const customToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton/>
    <GridToolbarFilterButton/>
    <GridToolbarDensitySelector/>
    <GridToolbarQuickFilter/>
  </GridToolbarContainer>
);

const QR_SX = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '5px solid #42a5f5'
};

const MobileBuildsTable = () => {
  const [tableRows, setTableRows] = useState<MobileBuildObjectsRow[]>([]);
  const {buildsClient} = useApiClients();

  const [searchParams] = useSearchParams();

  const fetchMobileBuilds = useCallback(async () => {
    const request = new ListBuildsRequest();
    request.setSvcname("mobile-apps");

    const buildObjectsResponse = await buildsClient.listBuilds(request);
    const rows: MobileBuildObjectsRow[] = buildObjectsResponse.getBuildsList()
      .map((item: BuildObject) => {
        const tagsMap = item.getTagsMap();
        const tagsObject = Array.from(tagsMap.entries())
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {} as Record<string, string>);

        return {
          id: item.getUrl(),
          uploaded: item.getLastmodified(),
          platform: tagsObject.platform,
          environment: tagsObject.environment,
          build: tagsObject.build,
          branch: tagsObject.branch,
        };
      });

    setTableRows(rows);

  }, [buildsClient]);

  useEffect(() => {
    fetchMobileBuilds();
  }, [fetchMobileBuilds]);

  const [qrOpen, setQrOpen] = useState<boolean>(false);
  const [qrData, setQrData] = useState<string>('');

  const columns: GridColDef[] = [
    {field: 'platform', headerName: 'Platform', width: 125},
    {field: 'environment', headerName: 'Env', width: 125},
    {field: 'build', headerName: 'Build', width: 125},
    {field: 'branch', headerName: 'Branch', width: 125},
    {
      field: 'uploaded',
      headerName: 'Uploaded',
      width: 150,
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return toDateTimeString(value as number);
      },
    },
    {
      field: 'download',
      headerName: 'Download',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const row = params.row as MobileBuildObjectsRow;
        if (row.platform === "android") {
          return (
            <IconButton href={params.id.toString()} target="_blank" rel="noopener" color='primary'>
              <FileDownload/>
            </IconButton>
          );
        } else {
          const url = `itms-services://?action=download-manifest&url=${params.id.toString()}`;
          if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            return (
              <IconButton href={url} target="_blank" rel="noopener" color='primary'>
                <FileDownload/>
              </IconButton>
            );
          }
          return (
            <IconButton color='primary' onClick={() => {
              setQrData(url);
              setQrOpen(true);
            }}>
              <FileDownload/>
            </IconButton>
          );
        }
      },
    },
  ];

  return (
    <>
      <DataGrid
        sx={{
          height: '75vh', width: '75vw', margin: 'auto', padding: 1.5
        }}
        initialState={{
          sorting: {sortModel: [{field: 'uploaded', sort: 'desc'}]},
          pagination: {paginationModel: {pageSize: 25}},
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [searchParams.get('q')],
            },
          },
        }}
        pageSizeOptions={[10, 25, 50]}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        rows={tableRows}
        columns={columns}
        slots={{toolbar: customToolbar}}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <Modal
        open={qrOpen}
        onClose={() => setQrOpen(false)}>
          <Box>
            <QRCodeSVG style={QR_SX} size={250} value={qrData}/>
          </Box>
      </Modal>
    </>
  );
};

export default MobileBuildsTable;
