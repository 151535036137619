import React, {createContext, ReactNode, useContext} from "react";
import {createTheme, ThemeProvider} from "@mui/material";

interface IColorModeContext {
  toggleColorMode: () => void;
}

const ColorModeContext = createContext<IColorModeContext | null>(null);

export const ColorModeProvider: React.FC<{ children: ReactNode }> = ({children}) => {
  const [mode, setMode] = React.useState<'light' | 'dark'>(
    localStorage.getItem('colorMode') === 'dark' ? 'dark' : 'light'
  );

  React.useEffect(() => {
    localStorage.setItem('colorMode', mode);
  }, [mode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const useColorMode = () => {
  const context = useContext(ColorModeContext);
  if (context === null) {
    throw new Error('useColorMode must be used within a ColorModeProvider');
  }

  return context;
};
