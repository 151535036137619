import {Box} from "@mui/material";

const MdmPortal = () => {
  return (
    <Box height='80vh' width='80vw'>
      <iframe
        src='https://mdm.dev.next.uniqkey.eu'
        width="100%"
        height="100%"
        style={{border: 0}}
        allowFullScreen
        title='MDM'
      ></iframe>
    </Box>
  )
};

export default MdmPortal;
