import {Navigate, Route, Routes} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import RouteEnum from './enums/RouteEnum';
import Auth0CallbackPage from './pages/Auth0CallbackPage';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import WelcomePage from "./pages/Welcome";
import EnvironmentsPage from "./pages/Environments";
import BuildsPage from "./pages/Builds";
import ExtensionBuilds from "./pages/ExtensionBuilds";
import DesktopBuilds from "./pages/DesktopBuilds";
import MobileBuilds from "./pages/MobileBuilds";
import EnvCard from "./pages/Environments/components/EnvCard";

const Router = () => {
  const {isAuthenticated} = useAuth0();

  const defaultRoute = isAuthenticated ? RouteEnum.Welcome : RouteEnum.Welcome;

  return (
    <>
      <Routes>
        <Route element={<UnauthenticatedRoute/>}>
          <Route path={RouteEnum.Auth0CallbackPage} element={<Auth0CallbackPage/>}/>
        </Route>
        <Route element={<AuthenticatedRoute/>}>
          <Route path={RouteEnum.Welcome} element={<WelcomePage/>}/>
          <Route path={RouteEnum.Builds} element={<BuildsPage/>}/>
          <Route path={`${RouteEnum.Builds}/extension`} element={<ExtensionBuilds/>}/>
          <Route path={`${RouteEnum.Builds}/desktop`} element={<DesktopBuilds/>}/>
          <Route path={`${RouteEnum.Builds}/mobile`} element={<MobileBuilds/>}/>
          <Route path={RouteEnum.Environments} element={<EnvironmentsPage/>}/>
          <Route path={`${RouteEnum.Environments}/:env`} element={<EnvCard/>}/>
        </Route>
        <Route path="*" element={<Navigate to={defaultRoute}/>}/>
      </Routes>
    </>
  );
};

export default Router;
